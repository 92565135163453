import { useMutation, useQuery } from "@apollo/react-hooks";
import { RouteComponentProps } from "@reach/router";
import { notification, Spin } from "antd";
import idx from "idx";
import GET_ROLE_BY_ID from "./getRoleById.graphql";
import {
  GetRoleById,
  GetRoleById_role as Role,
  GetRoleByIdVariables,
} from "./types/GetRoleById";
import { UpdateRole, UpdateRoleVariables } from "./types/UpdateRole";
import UPDATE_ROLE from "./updateRole.graphql";
import Authorize from "components/authorize";
import MainLayout from "components/layouts/main";
import RoleForm from "components/roleForm";
import withRouter from "components/withRouter";
import React, { FC, useCallback, useMemo } from "react";
import routes, { RoleEditParams } from "routes";
import { UpdateRoleDto } from "types/graphql-global-types";
import { pick, useErrorHandler, useNavigate } from "utils";

type Props = RouteComponentProps<RoleEditParams>;

const RoleEdit: FC<Props> = ({ id = "" }) => {
  const { loading, data, error } = useQuery<GetRoleById, GetRoleByIdVariables>(
    GET_ROLE_BY_ID,
    { variables: { id } }
  );

  const [updateRole, { loading: updating, error: updateError }] = useMutation<
    UpdateRole,
    UpdateRoleVariables
  >(UPDATE_ROLE, { refetchQueries: ["GetRoleList"] });

  useErrorHandler(updateError);
  useErrorHandler(error);

  const initialValues = useMemo<Partial<UpdateRoleDto>>(() => {
    return {
      ...pick(
        idx(data, x => x.role) || ({} as Partial<Role>),
        "name",
        "displayName",
        "description"
      ),
      permissions: (idx(data, x => x.role.permissions) || []).map(
        ({ name: permissionName }) => permissionName
      ),
    };
  }, [data]);

  const navigateToRoles = useNavigate(routes.roles);

  const handleSubmit = useCallback(
    async (input: Omit<UpdateRoleDto, "id">) => {
      const { data: updateData, errors } = await updateRole({
        variables: { input: { id: parseInt(id, 10), ...input } },
      });

      if (!errors) {
        notification.success({
          message: "Успех!",
          description: (
            <>
              Роль{" "}
              <strong>
                {idx(updateData, x => x.srv.role.update.displayName)}
              </strong>{" "}
              была успешно обновлена.
            </>
          ),
        });
      }

      navigateToRoles();
    },
    [updateRole, id, navigateToRoles]
  );

  return (
    <Authorize>
      <MainLayout
        title={`Редактирование роли "${idx(data, x => x.role.displayName)}"`}
      >
        <Spin spinning={loading || updating}>
          <RoleForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Spin>
      </MainLayout>
    </Authorize>
  );
};

export default withRouter({ path: routes.roleEdit })(RoleEdit);
